import React from 'react';

import { useUser } from '@apis/account/useUser';
import { Block } from '@components/layout/block';
import { Heading2 } from '@components/typographies/heading';
// import { EmailPreview } from '@features/mypage/BasicInfo/components/EmailPreview';
import { ShippingAddresses } from '@features/mypage/BasicInfo/components/ShippingAddresses';
import { UserPreview } from '@features/mypage/BasicInfo/components/UserPreview';
import useUserOld from '@hooks/useUser';

export const Detail = () => {
  const user = useUser();
  const { listShippingAddresses } = useUserOld();

  const {
    data: { shippingAddressList },
  } = listShippingAddresses();
  if (user === undefined) {
    return <p>Loading...</p>;
  }

  return (
    <Block width="100%" md={{ padding: '30px' }}>
      <Heading2 fontSize="24px" lh="40px">
        会員情報の確認・変更
      </Heading2>
      {/* <EmailPreview user={user} /> */}
      <UserPreview user={user} />
      <ShippingAddresses shippingAddressList={shippingAddressList} />
    </Block>
  );
};
