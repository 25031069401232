import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Mypage } from '@features/mypage';
import { Detail } from '@features/mypage/BasicInfo';

const DetailPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>マイページ | 会員情報の確認・変更 | {title}</title>
        {Description}
        {NoIndex}
      </Helmet>
      <Mypage>
        <Detail />
      </Mypage>
    </Layout>
  );
};
export default DetailPage;
